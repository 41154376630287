<template>
  <div class="container-fluid mt-3">
    <Form @submit="handleSubmit" v-slot="{ errors }">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Breadcrumb :parent_page="{title: 'الأماكن الإعلانية' , path: '/places'}" current_page="تعديل المكان الإعلاني"/>
        <div class="controls-btns d-flex gap-2 mb-2">
          <button class="btn btn-publish" type="submit" @click="handleSubmit" :disabled="loading">حفظ</button>
        </div>
      </div>

      <placement-form v-if="!loading" :errors="errors" :place="place" :handleChange="handleOnChange"/>

    </Form>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { Form} from "vee-validate";
import {editPlace, getPlace} from "@/services/ads";
import PlacementForm from "@/views/ads/componente/placeForm";

export default {
  name      : "Edit_adsPlace",
  components: {
    PlacementForm,
    Breadcrumb,
    Form,
  },
  data()
  {
    return {
      loading  : false,
      place: {}
    }
  },
  mounted() {
    this.fetchPlacementData();
  },
  methods: {
    fetchPlacementData(){
      this.loading = true
      getPlace(this.$route.params.place_id)
      .then(res=>{
        this.place = res.data
        this.loading = false
      })
      .catch(()=>this.loading = false)
    },
    handleOnChange(key, val)
    {
      this.place = {
        ...this.place,
        [key]: val
      }
    },
    handleSubmit() {
      this.loading = true
      editPlace(this.$route.params.place_id,this.place)
          .then(()=>{
            this.loading = false
            this.$router.push('/places')
          })
          .catch(()=>this.loading = false)
    }
  }
}
</script>

