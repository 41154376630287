<template>

  <el-card class="custom-card">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="المكان الإعلاني" name="placeDetails">
        <div class="row my-3">
          <div class="col-lg-10">
            <div class="d-flex flex-wrap gap-3">
              <reusable-field inputKey="name" label-text="اسم المكان" @update="handleChange" :prev_val="place.name"
                              :errors="errors" error-message="الرجاء إدخال اسم المكان"
              />

              <reusable-field inputKey="identifier" label-text="ID# المساحة" @update="handleChange" :prev_val="place.identifier"
                              :errors="errors" error-message="الرجاء إدخال المساحة"
              />

              <div class="form-group">
                <label for="identifier_type">نوع المساحة</label>
                <select id="identifier_type" class="form-select form-control-solid" v-model="place.type">
                  <option :value="type.id" v-for="type in placementType">{{ type.name }}</option>
                </select>
              </div>

              <reusable-field inputKey="height" label-text="الطول" @update="handleChange" :prev_val="place.height"
                              :errors="errors" error-message="الرجاء إدخال الطول بالبكسل"
              />

              <reusable-field inputKey="width" label-text="العرض" @update="handleChange" :prev_val="place.width"
                              :errors="errors" error-message="الرجاء إدخال العرض بالبكسل"
              />

            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-card>

</template>

<script>
import {Field} from "vee-validate";
import ReusableField from "@/components/formControl/reusableField";

export default {
  name      : "placementForm",
  components: {
    Field,
    ReusableField
  },
  props     : {
    errors      : {},
    handleChange: {
      default:  () => {}
    },
    place       : {
      default: {
        name      : '',
        identifier: '',
        type      : '',
        width     : '',
        height    : '',
        active    : true
      }
    }
  },
  data()
  {
    return {
      activeTab: 'placeDetails',

      placementType: [
        {id: 'web', 'name': 'ويب'},
        {id: 'mobile', 'name': 'موبايل'},
        {id: 'app', 'name': 'التطبيق'}
      ],

    }
  },
  methods: {
    isRequired(value)
    {
      return value ? true : 'This field is required';
    },

  }
}
</script>

<style>
.form-control,
select.form-select {
  width: 445px;
  max-width: 100%;
}
</style>
